.ant-btn-primary {
    color: #fff;
    background-color: #006ABF;
    border-color: #006ABF;
  }

.ant-btn-primary:hover {
    color: #fff;
    background-color: #004073;
    border-color: #004073;
}

.rightAlignNumber, .rightAlignNumber input {
  text-align: right;
}


/* Permission CSS Start*/

.p_order_agreement_view_none .p_order_agreement_view {
  display: none;
}

.p_order_agreement_edit_none .p_order_agreement_edit {
  display: none;
}
.p_order_ceremony_view_none .p_order_ceremony_view {
  display: none;
}

.p_order_ceremony_edit_none .p_order_ceremony_edit {
  display: none;
}

/* .p_product_name_view_none .p_product_name_view {
  display: none;
} */

/* .p_product_name_edit_none .p_product_name_edit {
  display: none;
} */
/* .p_product_price_view_none .p_product_price_view {
  display: none;
}
.p_product_price_edit_none .p_product_price_edit {
  display: none;
} */
.p_product_cost_view_none .p_product_cost_view {
  display: none;
}
.p_product_cost_edit_none .p_product_cost_edit {
  display: none;
}
.p_product_wholesale_price_view_none .p_product_wholesale_price_view {
  display: none;
}
.p_product_wholesale_price_edit_none .p_product_wholesale_price_edit {
  display: none;
}
/* .p_product_inventory_view_none .p_product_inventory_view {
  display: none;
}
.p_product_inventory_edit_none .p_product_inventory_edit {
  display: none;
} */

.p_product_category_view_none .p_product_category_view {
  display: none;
}

.p_product_category_edit_none .p_product_category_edit {
  display: none;
}
.p_invoice_view_none .p_invoice_view {
  display: none;
}

.p_invoice_edit_none .p_invoice_edit {
  display: none;
}
.p_invoice_monthly_view_none .p_invoice_monthly_view {
  display: none;
}

.p_invoice_monthly_edit_none .p_invoice_monthly_edit {
  display: none;
}

.p_receipt_view_none .p_receipt_view {
  display: none;
}

.p_receipt_edit_none .p_receipt_edit {
  display: none;
}

/* .p_report_view_none .p_report_view {
  display: none;
} */
.p_sales_report_view_none .p_sales_report_view {
  display: none;
}
.p_general_report_view_none .p_general_report_view {
  display: none;
}
.p_report_price_view_none .p_report_price_view {
  display: none;
}

/* .p_report_edit_none .p_report_edit {
  display: none;
} */
/* .p_report_price_edit_none .p_report_price_edit {
  display: none;
} */

.p_user_view_none .p_user_view {
  display: none;
}

.p_user_edit_none .p_user_edit {
  display: none;
}
.p_company_user_view_none .p_company_user_view {
  display: none;
}

.p_company_user_edit_none .p_company_user_edit {
  display: none;
}

.p_setting_view_none .p_setting_view {
  display: none;
}

.p_setting_edit_none .p_setting_edit {
  display: none;
}
/* .p_form_option_view_none .p_form_option_view {
  display: none;
} */

.p_form_option_edit_none .p_form_option_edit {
  display: none;
}
.p_pet_relation_view_none .p_pet_relation_view {
  display: none;
}

.p_pet_relation_edit_none .p_pet_relation_edit {
  display: none;
}
.p_permission_view_none .p_permission_view {
  display: none;
}
.p_permission_edit_none .p_permission_edit {
  display: none;
}
.p_audit_log_view_none .p_audit_log_view {
  display: none;
}
.p_order_status_view_none .p_order_status_view {
  display: none;
}

.p_order_status_edit_none .p_order_status_edit {
  display: none;
}

.p_order_hydration_view_none .p_order_hydration_view {
  display: none;
}

.p_order_hydration_edit_none .p_order_hydration_edit {
  display: none;
}

.p_order_unlock_none .p_order_unlock {
  display: none;
}

/* .p_order_edit_none .p_order_edit {
  display: none;
} */


/* Permission CSS End*/
